// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCL8lwMYxPMai5uSDRlRs-Z-2e297_ioN4',
  authDomain: 'fitabet-com.firebaseapp.com',
  databaseURL: 'https://fitabet-com-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'fitabet-com',
  storageBucket: 'fitabet-com.appspot.com',
  messagingSenderId: '809413359282',
  appId: '1:809413359282:web:f261fc070aaa300fc5bbab',
  measurementId: 'G-FYEL57SMHN',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { app, db, auth };
