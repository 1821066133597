import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useFirestore } from '../../hooks/useFirestore';
import styles from './TransactionForm.module.css';

export default function TransactionForm({ uid }) {
  const navigate = useNavigate();

  const [goalDescription, setGoalDescription] = useState('');
  const [goalBet, setGoalBet] = useState('');
  const [goalDuration, setGoalDuration] = useState('');
  const daysCompleted = 0;

  const { addDocument, response } = useFirestore('goals');

  const handleSubmit = (e) => {
    e.preventDefault();
    addDocument({
      uid,
      goalDescription,
      goalBet,
      goalDuration,
      daysCompleted,
    });

    navigate('/overview');
  };

  // reset the form fields
  useEffect(() => {
    if (response.success) {
      setGoalDescription('');
      setGoalBet('');
      setGoalDuration('');
    }
  }, [response.success]);

  return (
    <div id={styles.registrationContainer}>
      <h3>Add a health bet</h3>
      <form onSubmit={handleSubmit}>
        <label>
          <span>I bet ... €</span>
          <input type='number' required onChange={(e) => setGoalBet(e.target.value)} value={goalBet} placeholder='enter bet (e.g., 30€)' />
        </label>

        <label>
          <span>that I will ...</span>
          <input
            type='text'
            required
            onChange={(e) => setGoalDescription(e.target.value)}
            value={goalDescription}
            placeholder='enter goal (e.g., run every day)'
          />
        </label>

        <label>
          <span>for ... days</span>
          <input
            type='number'
            required
            onChange={(e) => setGoalDuration(e.target.value)}
            value={goalDuration}
            placeholder='enter number of days (e.g., 30)'
          />
        </label>

        <label>
          <span>days</span>
        </label>

        <button id={styles.registrationButton}>💪 Let's gooooo!</button>
      </form>
    </div>
  );
}
