import { Link } from 'react-router-dom';
import './CreateGoalButton.css'; // Path to your CSS file

const CreateGoalButton = ({ children }) => {
  return (
    <Link to='/createGoal' className='cool-button'>
      {children}
    </Link>
  );
};

export default CreateGoalButton;
