import { useEffect, useState, useRef } from 'react';
import { db } from '../firebase/config';
import { collection, onSnapshot, query, where, orderBy } from 'firebase/firestore';

export const useCollection = (collectionName, _whereParams, _orderByParams) => {
  const [documents, setDocuments] = useState(null);
  const [error, setError] = useState(null);
  // using useRef to prevent infinite re-evaluation loop
  const whereParams = useRef(_whereParams).current;
  const orderByParams = useRef(_orderByParams).current;

  useEffect(() => {
    let ref = collection(db, collectionName);

    if (whereParams) {
      ref = query(ref, where(...whereParams));
    }
    if (orderByParams) {
      ref = query(ref, orderBy(...orderByParams));
    }

    const unsubscribe = onSnapshot(
      ref,
      (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });

        setDocuments(results);
        setError(null);
      },
      (error) => {
        console.log(error);
        setError("Couldn't fetch data");
      }
    );

    return () => unsubscribe();
  }, [collectionName, whereParams, orderByParams]);

  return { documents, error };
};
