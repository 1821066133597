import { useAuthContext } from '../../hooks/useAuthContext';
import { useCollection } from '../../hooks/useCollection';

import styles from './Overview.module.css';

import CreateGoalButton from './CreateGoalButton';
import GoalList from './GoalList';

export default function Overview() {
  const { user } = useAuthContext();
  const { documents, error } = useCollection('goals', ['uid', '==', user.uid], ['createdAt', 'desc']);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.component}>
          <h1>Overview</h1>
          <CreateGoalButton>Create New Goal</CreateGoalButton>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.component}>
          <h1>My goals</h1>
          {error && <p>{error}</p>}
          {documents && <GoalList goals={documents} />}
        </div>
      </div>
    </>
  );
}
