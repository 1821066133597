import React from 'react';
import { useAuthContext } from './hooks/useAuthContext';

// import React, { useState } from 'react';
// import './App.css';
// import { Button, Typography, Container } from '@mui/material';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

//pages
import Home from './pages/home/Home';
import Overview from './pages/overview/Overview';
import CreateGoal from './pages/createGoal/CreateGoal';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import Navbar from './components/Navbar';

function App() {
  // const [selectedImage, setSelectedImage] = useState(null);

  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedImage(file);
  // };

  const { authIsReady, user } = useAuthContext();

  return (
    <div className='App'>
      {authIsReady && (
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path='/' element={!user ? <Home /> : <Navigate to='/overview' />} />
            <Route path='/overview' element={user ? <Overview /> : <Navigate to='/signup' />} />
            <Route path='/createGoal' element={user ? <CreateGoal /> : <Navigate to='/signup' />} />
            <Route path='/login' element={!user ? <Login /> : <Navigate to='/overview' />} />
            <Route path='/signup' element={!user ? <Signup /> : <Navigate to='/overview' />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
    // <Container align='center'>
    //   <Typography variant='h2' gutterBottom style={{ marginTop: '50px' }}>
    //     FitaBet
    //   </Typography>

    //   <div style={{ marginTop: '100px', marginBottom: '50px' }}>
    //     <input type='file' accept='image/*' style={{ display: 'none' }} id='image-upload' onChange={handleImageUpload} />
    //     <label htmlFor='image-upload'>
    //       <Button variant='contained' component='span' color='success' startIcon={<CloudUploadIcon />}>
    //         Upload Selfie
    //       </Button>
    //     </label>

    //     {selectedImage && (
    //       <div>
    //         <Typography variant='h4'>Selected Image:</Typography>
    //         <img src={URL.createObjectURL(selectedImage)} alt='Selected' style={{ maxWidth: '200px', marginTop: '10px' }} />
    //       </div>
    //     )}
    //   </div>

    //   <Typography variant='h4'>OR</Typography>

    //   <div style={{ marginTop: '50px', marginBottom: '50px' }}>
    //     <Typography variant='h4'>
    //       loose <b>15€</b>
    //     </Typography>
    //   </div>
    // </Container>
  );
}

export default App;
