import { Link } from 'react-router-dom';
import styles from './Home.module.css';

export default function Home() {
  return (
    <>
      <div className={styles.container}>
        <img src='assets/cat.gif' alt='kitten' />
        <h1>
          Make a <u>bet</u> to get <u>fit</u>
        </h1>
        <ul className={styles.ul}>
          <li className={styles.li}>
            1. Make a <b>bet</b>
          </li>
          <li className={styles.li}>2. Upload 📷 daily</li>
          <li className={styles.li}>3. Redeem or donate 💵</li>
        </ul>
        <br />
        <br />

        <h2>Why it works?</h2>
        <p>Research shows that we need to increase the penalty of not sticking with our habits</p>
        <iframe
          src='https://www.youtube.com/embed/Y-d90cILIDk?si=EJVrJr2eoacWkrAz&amp;controls=1&amp;start=1048'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowfullscreen
        ></iframe>

        <br />
        <br />

        <h2>How it works?</h2>
        <p>
          I had a problem that <b>I would forget to take my vitamin D</b> supplements on the regular basis. Hence, I decided to nudge myself
          and I made a public commitment - <b>I made a bet with myself for 30€</b> that I would not <b>miss a single pill for 30 days</b>. I
          get 1€ back for every day I continue the challenge. But if I miss...
        </p>
        <br />
        <br />

        <h2>But, what happens if I miss?</h2>
        <img src='assets/sad-peach.gif' alt='sad peach' />
        <p>
          Well - for every day you skip your goal, you loose a proportion of your bet. So in my case, I would loose 1€ for every day I skip
          my vitamin D supplements.
        </p>
        <br />
        <br />

        <h2>But if I succeed?!</h2>
        <img src='assets/donations.jpg' alt='donations' />
        <p>Good job! You've made it! Now you can take your deposit back OR you can donate this money to a charitable organisation. </p>
        <br />
        <br />

        <h2>Are you ready?</h2>
        <img src='assets/happy-mocha.gif' alt='happy-mocha' />
        {/* className='cool-button' */}
        <Link to='/signup' className={styles.button}>
          Start your bet
        </Link>
      </div>
    </>
  );
}
