import React, { useRef } from 'react';

import styles from './GoalList.module.css';

export default function GoalList({ goals }) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the hidden file input click event
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // You now have access to the selected file
      // Process the file as required, e.g., upload it to a server
      console.log(file);
    }
  };

  return (
    <ul className={styles.goals}>
      {!goals && <p>No goals yet</p>}
      {goals.map((goal) => (
        <li key={goal.id} id={styles.goalItem}>
          <p className={styles.name}>
            <b>{goal.goalDescription}</b> for {goal.goalDuration} days
          </p>
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={{ width: `${5 + (goal.daysCompleted / goal.goalDuration) * 100}%` }}></div>
          </div>
          <p className={styles.amount}>
            or <b>lose 🔥{goal.goalBet}€</b>
          </p>
          {/* 
          <button className={styles.proveButton}>Take 📷 selfie to prove that you⚡</button> */}

          <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} accept='image/*' />
          <button className={styles.proveButton} onClick={handleButtonClick}>
            Take 📷 selfie to prove that you⚡
          </button>
        </li>
      ))}
    </ul>
  );
}
