import { useState } from 'react';
import { useSignup } from '../../hooks/useSignup';

import styles from './Signup.module.css';

export default function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const { signup, isPending, error } = useSignup();

  const handleSubmit = (e) => {
    e.preventDefault();
    signup(email, password, displayName);
  };

  return (
    <form className={styles['signup-form']} onSubmit={handleSubmit}>
      <h2>Signup</h2>
      <label>
        <span>email:</span>
        <input name='email' autoComplete='email' type='email' onChange={(e) => setEmail(e.target.value)} value={email}></input>
      </label>
      <label>
        <span>password:</span>
        <input name='password' type='password' onChange={(e) => setPassword(e.target.value)} value={password}></input>
      </label>
      <label>
        <span>display name:</span>
        <input
          name='firstname'
          autoComplete='given-name'
          type='text'
          onChange={(e) => setDisplayName(e.target.value)}
          value={displayName}
        ></input>
      </label>
      {!isPending && <button className='btn'>Signup</button>}
      {isPending && (
        <button className='btn' disabled>
          loading...
        </button>
      )}
      {error && <p>{error}</p>}
    </form>
  );
}
