import { useAuthContext } from '../../hooks/useAuthContext';

import styles from './CreateGoal.module.css';

import TransactionForm from './TransactionForm';

export default function CreateGoal() {
  const { user } = useAuthContext();
  return (
    <>
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <TransactionForm uid={user.uid} />
        </div>
      </div>
    </>
  );
}
